import { forwardRef } from "react"
// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
// Custom styles for MDTypography
import MDTypographyRoot from "../../components/MDTypography/MDTypographyRoot"
// Material Dashboard 2 React contexts
import { useMaterialUIController } from "../context"

const MDTypography = forwardRef(({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  return (
    <MDTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
        darkMode
      }}>
      {children}
    </MDTypographyRoot>
  )
})

// Setting default values for the props of MDTypography
MDTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1
}

export default MDTypography
