import React, { forwardRef } from "react"
import { Dialog, DialogActions, Slide, Typography } from "@mui/material"
import MDBox from "../../components/MDBox"
import MDButton from "../../components/MDButton"
import closeIcon from "../../icons/closeIcon"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TermsAndConditionsDialog = ({ open, onAccept, showAcceptButton, onCloseDialog }) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          margin: 3,
          fontFamily: "sans-serif",
          letterSpacing: "0.333em",
          lineHeight: "1.5em"
        }
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth>
      <MDBox pl={5} pt={5} pb={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h5">Terms and Conditions</Typography>
        <MDButton onClick={onCloseDialog}> {closeIcon}</MDButton>
      </MDBox>
      <MDBox px={5} pb={5} display="flex" flexDirection="column" gap="16px">
        <Typography variant="h6" py={1}>
          1. Introduction
        </Typography>
        <Typography variant="body2" pl={2}>
          These Terms and Conditions ("Agreement") govern the use of the services ("Service") that are made available by MyDigitalEstate ("we", "us", "our", or
          "MyDigitalEstate"). These Terms and Conditions represent the whole agreement and understanding between MyDigitalEstate and the individual or entity
          who subscribes to our Service ("Subscriber" or "you").
        </Typography>
        <Typography variant="body2" pl={2}>
          By using our Platform, you agree to these Terms, which we may update from time to time. You should review these Terms regularly as they affect your
          rights and liabilities under law.
        </Typography>
        <Typography variant="h6" py={1}>
          2. Description of Service
        </Typography>
        <Typography variant="body2" pl={2}>
          MyDigitalEstate is an investment platform which allows users to invest cryptocurrencies in real estate properties that will be rented out on Airbnb.
          Profits from these rentals will be shared among the investors based on the percentage of their investment.
        </Typography>
        <Typography variant="h6" py={1}>
          3. Acceptance of Terms
        </Typography>
        <Typography variant="body2" pl={2}>
          By registering for and/or using the Services in any manner, you agree to all of the terms and conditions contained herein.
        </Typography>
        <Typography variant="h6" py={1}>
          4. Eligibility
        </Typography>
        <Typography variant="body2" pl={2}>
          You must be of legal age according to the laws of your country to use the MyDigitalEstate app for real estate investment purposes. By using the app,
          you confirm that you meet the age requirements and are legally permitted to participate in real estate investments.
        </Typography>
        <Typography variant="h6" py={1}>
          5. Investment Returns
        </Typography>
        <Typography variant="body2" pl={2}>
          Returns on investments are based on the profits generated from the rental of properties on Airbnb. The percentage return is based on your
          proportionate share of the total investment.
        </Typography>
        <Typography variant="h6" py={1}>
          6. Referrals
        </Typography>
        <Typography variant="body2" pl={2}>
          Users may also earn returns by referring other users to invest with MyDigitalEstate. The percentage return from referrals is determined by your rank
          within our platform. The terms and conditions of the referral program will be outlined in the app and are subject to change at MyDigitalEstate's
          discretion.
        </Typography>
        <Typography variant="h6" py={1}>
          7. Ranks
        </Typography>
        <Typography variant="body2" pl={2}>
          Ranks are unlocked by either inviting people or investing in properties through our platform. Higher ranks yield higher referral percentages.
        </Typography>
        <Typography variant="h6" py={1}>
          8. Non-Refundable Investments
        </Typography>
        <Typography variant="body2" pl={2}>
          Investments made through MyDigitalEstate cannot be refunded for any reason whatsoever, including but not limited to, a change in personal
          circumstances, market volatility or dissatisfaction with our Services.
        </Typography>
        <Typography variant="h6" py={1}>
          9. Risk Disclosure
        </Typography>
        <Typography variant="body2" pl={2}>
          Investing in real estate involves inherent risks and should only be done as part of a diversified portfolio. Past performance is not indicative of
          future results, and there are no guarantees of profit or loss avoidance. Users are solely responsible for their investment decisions and must conduct
          thorough research and due diligence before investing through MyDigitalEstate.{" "}
        </Typography>
        <Typography variant="h6" py={1}>
          10. Investment Availability
        </Typography>
        <Typography variant="body2" pl={2}>
          While MyDigitalEstate strives to provide access to properties in various locations, the availability of investments may vary based on market
          conditions and other factors. MyDigitalEstate does not guarantee the availability of specific investment opportunities at any given time.
        </Typography>
        <Typography variant="h6" py={1}>
          11. Compliance with Laws:
        </Typography>
        <Typography variant="body2" pl={2}>
          Users of the MyDigitalEstate app must comply with all applicable laws and regulations in their respective countries related to real estate
          investments, cryptocurrency usage, and financial transactions.
        </Typography>
        <Typography variant="h6" py={1}>
          12. Limitation of Liability
        </Typography>
        <Typography variant="body2" pl={2}>
          In no event shall MyDigitalEstate, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental,
          special, consequential or punitive damages arising out of your use of our Services. MyDigitalEstate does not guarantee the performance of any
          investment provided in the app.
        </Typography>
        <Typography variant="h6" py={1}>
          13. User Conduct
        </Typography>
        <Typography variant="body2" pl={2}>
          You agree not to engage in any illegal conduct or conduct that infringes upon the rights of others while using our Services.
        </Typography>
        <Typography variant="body2" pl={2}>
          You are solely responsible for maintaining the security of your account credentials.
        </Typography>
        <Typography variant="h6" py={1}>
          14. Data Privacy:
        </Typography>
        <Typography variant="body2" pl={2}>
          MyDigitalEstate values your privacy and handles your personal information in accordance with our Privacy Policy, which can be found within the app. By
          using the MyDigitalEstate app, you consent to the collection, use, and storage of your personal information as outlined in the Privacy Policy.
        </Typography>
        <Typography variant="h6" py={1}>
          15. Intellectual Property Rights
        </Typography>
        <Typography variant="body2" pl={2}>
          The MyDigitalEstate app and all its content, including but not limited to logos, trademarks, text, graphics, images, videos, and software, are the
          property of MyDigitalEstate and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the app
          without prior written consent from MyDigitalEstate.
        </Typography>
        <Typography variant="h6" py={1}>
          16. Changes to These Terms
        </Typography>
        <Typography variant="body2" pl={2}>
          We reserve the right to modify these terms at any time with a relative email notice. Continued use of the app after any changes constitute acceptance
          of the updated Terms and Conditions.
        </Typography>
        <Typography variant="h6" py={1}>
          17. Contact Information
        </Typography>
        <Typography variant="body2" pl={2}>
          If you have any questions about these Terms & Conditions please contact us at support@mydigitalestate.app
        </Typography>
        <Typography variant="body2" pl={2}>
          By accepting these terms and conditions you acknowledge that you have read, understand and agree to them in their entirety.
        </Typography>
      </MDBox>
      <DialogActions sx={{ pb: "30px" }}>
        <MDButton onClick={onCloseDialog}>Close</MDButton>
        {showAcceptButton && (
          <MDButton color="info" variant="gradient" onClick={onAccept}>
            Accept
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default TermsAndConditionsDialog
