import { Typography } from "@mui/material"
import React from "react"
import MDBox from "../../MDBox"
import logo from "../../../logos"
import MDButton from "../../MDButton"
import MDTypography from "../../MDTypography"

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <MDBox
      sx={{
        textAlign: "center",
        zIndex: 11,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        height: "100%",
        background: "aliceblue"
      }}>
      <style>
        {`
          body, html {
overflow: hidden;
          }`}
      </style>
      {logo}
      <MDTypography variant="h5">We're really sorry 😔 </MDTypography>
      <br />
      <br />
      <br />
      <Typography fontWeight={"regular"} variant="h6">
        Something went wrong:
      </Typography>
      <pre style={{ maxWidth: "80vw", margin: "auto", whiteSpace: "break-spaces", userSelect: "text" }}>{error}</pre>
      <br />
      <br />
      <MDButton variant="gradient" color="info" onClick={() => window.location.reload()}>
        Try reloading
      </MDButton>
    </MDBox>
  )
}

export default ErrorFallback
