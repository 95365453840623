import React from "react"
import MDTypography from "../../components/MDTypography"

export const TermsAndConditions = ({ fontSize, onClick }) => {
  return (
    <MDTypography
      component="a"
      href="#"
      variant="button"
      fontWeight="bold"
      color="info"
      sx={{ fontSize: fontSize || "0.875rem" }}
      textGradient
      onClick={onClick}>
      Terms and Conditions
    </MDTypography>
  )
}
