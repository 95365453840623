const logoText = (
  <svg width={"200px"} viewBox="0 0 309.5 44.7">
    <style>{".st0{fill:#58c1d5}.st1{fill:#2aade4}.st2{fill:#396cb3}"}</style>
    <path
      className="st0"
      d="M28.1 19.9c-.2-4.5-.5-9.9-.5-14h-.1c-1.1 3.8-2.4 7.8-4.1 12.3l-5.7 15.7h-3.2L9.3 18.5C7.8 13.9 6.5 9.7 5.6 5.9h-.1c-.1 4-.3 9.5-.6 14.3L4 34.1H0L2.3 1.7h5.3l5.5 15.7c1.3 4 2.4 7.5 3.3 10.9h.1c.8-3.3 2-6.8 3.4-10.9l5.8-15.7H31l2 32.4h-4.1l-.8-14.2zM41.2 10.8l5.1 13.7c.5 1.5 1.1 3.4 1.5 4.8h.1c.4-1.4.9-3.2 1.5-4.8L54 10.8h4.5l-6.3 16.6c-3 8-5.1 12.1-8 14.5-2.1 1.8-4.1 2.5-5.2 2.7L37.9 41c1.1-.3 2.4-1 3.7-2.1 1.2-.9 2.6-2.5 3.6-4.7.2-.4.3-.8.3-1s-.1-.6-.3-1.1l-8.6-21.4h4.6z"
    />
    <path
      className="st1"
      d="M62.4 2.2c2.6-.4 5.6-.7 8.9-.7 6 0 10.3 1.4 13.1 4 2.9 2.6 4.6 6.4 4.6 11.6 0 5.3-1.6 9.6-4.7 12.6-3 3-8 4.7-14.3 4.7-3 0-5.5-.1-7.6-.4V2.2zm4.2 28.6c1.1.2 2.6.2 4.2.2 8.9 0 13.8-5 13.8-13.7 0-7.6-4.3-12.5-13.1-12.5-2.2 0-3.8.2-4.9.4v25.6zM99 4.3c0 1.4-1 2.6-2.7 2.6-1.5 0-2.5-1.2-2.5-2.6 0-1.5 1.1-2.6 2.6-2.6S99 2.8 99 4.3zm-4.8 29.8V10.8h4.2V34h-4.2zM125.5 10.8c-.1 1.7-.2 3.6-.2 6.4v13.5c0 5.3-1.1 8.6-3.3 10.6-2.3 2.1-5.5 2.8-8.4 2.8-2.8 0-5.9-.7-7.7-1.9L107 39c1.5 1 3.9 1.8 6.8 1.8 4.3 0 7.5-2.3 7.5-8.1v-2.6h-.1c-1.3 2.2-3.8 3.9-7.4 3.9-5.8 0-9.9-4.9-9.9-11.3 0-7.9 5.1-12.3 10.5-12.3 4 0 6.2 2.1 7.2 4h.1l.2-3.5h3.6zm-4.4 9.2c0-.7 0-1.3-.2-1.9-.8-2.4-2.8-4.5-5.9-4.5-4 0-6.9 3.4-6.9 8.8 0 4.6 2.3 8.4 6.9 8.4 2.6 0 4.9-1.6 5.9-4.3.2-.7.3-1.5.3-2.3V20zM136.7 4.3c0 1.4-1 2.6-2.7 2.6-1.5 0-2.5-1.2-2.5-2.6 0-1.5 1.1-2.6 2.6-2.6 1.6 0 2.6 1.1 2.6 2.6zM132 34.1V10.8h4.2V34H132zM148.4 4.2v6.7h6v3.2h-6v12.5c0 2.9.8 4.5 3.2 4.5 1.1 0 1.9-.1 2.4-.3l.2 3.2c-.8.3-2.1.6-3.7.6-2 0-3.6-.6-4.6-1.8-1.2-1.2-1.6-3.3-1.6-6V14.1h-3.6v-3.2h3.6V5.3l4.1-1.1zM172 34.1l-.3-2.9h-.1c-1.3 1.8-3.8 3.5-7.1 3.5-4.7 0-7.1-3.3-7.1-6.7 0-5.6 5-8.7 14-8.6v-.5c0-1.9-.5-5.4-5.3-5.4-2.2 0-4.4.7-6 1.7l-1-2.8c1.9-1.2 4.7-2.1 7.6-2.1 7.1 0 8.8 4.8 8.8 9.5v8.7c0 2 .1 4 .4 5.6H172zm-.6-11.9c-4.6-.1-9.8.7-9.8 5.2 0 2.7 1.8 4 4 4 3 0 4.9-1.9 5.6-3.9.1-.4.2-.9.2-1.3v-4zM182.3 0h4.2v34.1h-4.2V0z"
    />
    <path
      className="st2"
      d="M210.5 18.9h-12.6v11.7h14v3.5h-18.2V1.7h17.5v3.5h-13.3v10.2h12.6v3.5zM216.7 29.8c1.2.8 3.5 1.7 5.6 1.7 3.1 0 4.5-1.5 4.5-3.5s-1.2-3.1-4.3-4.3c-4.2-1.5-6.1-3.8-6.1-6.6 0-3.7 3-6.8 8-6.8 2.4 0 4.4.7 5.7 1.4l-1.1 3.1c-.9-.6-2.6-1.3-4.8-1.3-2.5 0-3.9 1.4-3.9 3.2 0 1.9 1.4 2.8 4.4 3.9 4 1.5 6.1 3.6 6.1 7 0 4.1-3.2 7-8.7 7-2.5 0-4.9-.6-6.5-1.6l1.1-3.2zM241.4 4.2v6.7h6v3.2h-6v12.5c0 2.9.8 4.5 3.2 4.5 1.1 0 1.9-.1 2.4-.3l.2 3.2c-.8.3-2.1.6-3.7.6-2 0-3.6-.6-4.6-1.8-1.2-1.2-1.6-3.3-1.6-6V14.1h-3.6v-3.2h3.6V5.3l4.1-1.1zM265 34.1l-.3-2.9h-.1c-1.3 1.8-3.8 3.5-7.1 3.5-4.7 0-7.1-3.3-7.1-6.7 0-5.6 5-8.7 14-8.6v-.5c0-1.9-.5-5.4-5.3-5.4-2.2 0-4.4.7-6 1.7l-1-2.8c1.9-1.2 4.7-2.1 7.6-2.1 7.1 0 8.8 4.8 8.8 9.5v8.7c0 2 .1 4 .4 5.6H265zm-.6-11.9c-4.6-.1-9.8.7-9.8 5.2 0 2.7 1.8 4 4 4 3 0 4.9-1.9 5.6-3.9.1-.4.2-.9.2-1.3v-4zM280.2 4.2v6.7h6v3.2h-6v12.5c0 2.9.8 4.5 3.2 4.5 1.1 0 1.9-.1 2.4-.3l.2 3.2c-.8.3-2.1.6-3.7.6-2 0-3.6-.6-4.6-1.8-1.2-1.2-1.6-3.3-1.6-6V14.1h-3.6v-3.2h3.6V5.3l4.1-1.1zM293.1 23.2c.1 5.7 3.7 8.1 8 8.1 3 0 4.8-.5 6.4-1.2l.7 3c-1.5.7-4 1.4-7.7 1.4-7.2 0-11.4-4.7-11.4-11.7 0-7 4.1-12.5 10.9-12.5 7.6 0 9.6 6.7 9.6 10.9 0 .9-.1 1.5-.1 2h-16.4zm12.3-3c0-2.7-1.1-6.9-5.9-6.9-4.3 0-6.1 3.9-6.5 6.9h12.4z"
    />
  </svg>
)
export default logoText
