import { Suspense, lazy, useEffect, useState } from "react"
import theme from "./asset/theme"
import "./App.css"
import { ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import "./asset/css/global.css"
import { useLocation, useNavigate } from "react-router-dom"
import { supabase } from "./supabaseClient"
import LoginPage from "./pages/login"
import RegisterPage from "./pages/register"
import { AuthContext } from "./components/context"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./components/Suspense/ErrorBoundary/ErrorFallback"
import LogoLoading from "./components/Suspense/CoverLoading/LogoLoading"
import MessageOnScreenSize from "./components/MessageScreenSize/MessageOnScreenSize"
import { noLandscape } from "./components/NoLandscape"

// import { useAuth } from "../context"
// const user = useAuth()
// import Plausible from 'plausible-tracker';

// const plausible = Plausible({
//   domain: 'localhost:3000'
// })

// plausible('eventName', { property: 'value' });

// plausible('formSubmission', { formData: { name: 'username', email: 'user?.user_metadata?.username' } });

const App = lazy(() => import("./App"))
function AppContainer() {
  const [userState, setUserState] = useState()
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [profileData, setProfileData] = useState()
  const hideDesktopScreen = window.innerWidth < 1900
  useEffect(() => {
    async function getUser() {
      const {
        data: { session }
      } = await supabase.auth.getSession()
      if (session) {
        setUserState(session.user)
        window.mde_session = session
        window.addEventListener("load", function () {
          // eslint-disable-next-line no-undef
          progressier.add({
            id: window.mde_session.user_metadata?.username
          })
        })
        setLoading(false)
      } else {
        if (pathname.substring(1) && !["dashboard", "register"].includes(pathname.substring(1))) checkUsername()
        else setLoading(false)
      }
    }
    getUser()
  }, [])
  useEffect(() => {
    if (pathname === "/login" && loading === false) navigate("/")
    if (pathname === "/register" && loading === false) navigate("/market")
  }, [userState])
  async function checkUsername() {
    const { data: profileData, error: usersError } = await supabase.from("profiles_view").select("username").eq("username", pathname.substring(1)).single()
    setProfileData(profileData)
    setLoading(false)
  }
  if (loading) return <LogoLoading />
  return (
    <ThemeProvider theme={theme}>
      {noLandscape}
      <CssBaseline />
      <AuthContext.Provider value={userState}>
        {userState ? (
          <ErrorBoundary Fallback={<ErrorFallback />}>
            <Suspense fallback={<LogoLoading />}>
              <ThemeProvider theme={theme}>
                {!hideDesktopScreen ? <MessageOnScreenSize /> : <App />}
                {/* This should be active on 600px screen only when the page will be in production (for the moment I am setting the pixel in 1900 so we can work on) */}
              </ThemeProvider>
            </Suspense>
          </ErrorBoundary>
        ) : pathname === "/register" || pathname === `/${profileData?.username}` ? (
          <RegisterPage updateSessionFunction={setUserState} inviter={profileData?.username} />
        ) : (
          <LoginPage updateSessionFunction={setUserState} />
        )}
      </AuthContext.Provider>
    </ThemeProvider>
  )
}
export default AppContainer
