import { useState } from "react"
import { Link } from "react-router-dom"
import Card from "@mui/material/Card"
import MDBox from "../../components/MDBox"
import MDTypography from "../../components/MDTypography"
import MDInput from "../../components/MDInput"
import MDButton from "../../components/MDButton"
import { supabase } from "../../supabaseClient"
import { useToast } from "@dalgu/react-toast"
import logo from "../../logos"
import Footer from "../../components/Footer"

function LoginPage({ updateSessionFunction }) {
  const { showToast } = useToast()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase())
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const handleLogin = async event => {
    event.preventDefault()
    const { data: profile } = await supabase.from("profiles_view").select("email").eq("username", email).single()
    let { data, error } = await supabase.auth.signInWithPassword({
      email: profile?.email || email,
      password: password
    })
    if (error) {
      showToast(error.message)
    } else {
      updateSessionFunction(data.user)
      // setLoginCount(prevCount => prevCount + 1)
      // plausible.track("login")
    }
  }

  const resetPassword = async event => {
    event.preventDefault()
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://mydigitalestate.app"
    })
    if (error) {
      showToast(error.message)
    } else {
      showToast("Password reset email has been sent.")
    }
  }

  // DO NOT DELETE
  // const handleAppleLogin = async () => {
  //   const { data, error } = await supabase.auth.signInWithOAuth({
  //     provider: "apple"
  //   })

  //   if (error) {
  //     console.error("Apple login error:", error.message)
  //     return
  //   }
  // }
  return (
    <MDBox sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "space-between" }}>
      <MDBox sx={{ display: "flex", mx: "auto", maxWidth: "400px", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <Card
          sx={{
            background: "rgb(255 255 255 / 75%)",
            width: "100%"
          }}>
          <MDBox px={2} pt={2}>
            {logo}
          </MDBox>
          <MDBox mt={0} pt={4} pb={3} px={3}>
            <MDBox className="loginForm" component="form" role="form" onSubmit={handleLogin}>
              <MDBox mb={2}>
                <MDInput type="text" label="Email or Username" fullWidth autoFocus onChange={handleEmailChange} value={email} />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password" fullWidth onChange={handlePasswordChange} value={password || null} autoComplete="current-password" />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Login
                </MDButton>
              </MDBox>
              {/* DO NOT DELETE <MDBox mt={3} mb={1} textAlign="center">
              <MDButton variant="gradient" color="info" fullWidth onClick={handleAppleLogin}>
                Login with Apple
              </MDButton>
            </MDBox> */}
              <MDBox pt={3} textAlign="center" color="#3b94ee">
                <MDTypography variant="button" sx={{ color: "#7b809a" }}>
                  Forgot password?{" "}
                </MDTypography>
                <MDTypography component={Link} onClick={resetPassword} variant="button" sx={{ cursor: "pointer" }} fontWeight="medium">
                  Click here
                </MDTypography>
              </MDBox>
              <MDBox mb={1} textAlign="center" color="#3b94ee">
                <MDTypography variant="button" sx={{ color: "#7b809a" }}>
                  Don't have an account yet?{" "}
                </MDTypography>
                <MDTypography component={Link} to="/register" variant="button" color="primary" sx={{ cursor: "pointer" }} fontWeight="medium">
                  Register here
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <MDBox sx={{ pb: "32px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 0, margin: 0 }}>
        <Footer />
      </MDBox>
    </MDBox>
  )
}

export default LoginPage
