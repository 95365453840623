import React from "react"
import MDTypography from "../MDTypography"
import MDBox from "../MDBox"
import logoText from "../../logos/logotext"
import logo from "../../logos/logo"
import { Card } from "@mui/material"

const MessageOnScreenSize = () => {
  return (
    <>
      <Card sx={{ mx: "auto", maxWidth: "600px", display: "flex", alignItems: "center", py: 4, my: 5 }}>
        <MDBox flex="1" display="flex" pl={2} justifyContent="center" py={"6px"}>
          <MDBox height="auto" pt={1.1} width={40}>
            {logo}
          </MDBox>
          <MDBox height="auto" p={2} width={230}>
            {logoText}
          </MDBox>
        </MDBox>
        <MDTypography display="block" variant="h4" py={0.5}>
          We're sorry!
        </MDTypography>
        <MDTypography display="block" variant="h5" py={1} fontWeight="regular" sx={{ textAlign: "center" }}>
          The application is available only on mobile phones. <br />
          Resize your window to enjoy the app from desktop.
        </MDTypography>
      </Card>
    </>
  )
}

export default MessageOnScreenSize
