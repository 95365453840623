import React, { useEffect } from "react"
import logo from "../../../logos/logo"
import { Box } from "@mui/material"

function LogoLoading() {
  useEffect(() => {
    const blockScroll = event => {
      event.preventDefault()
    }
    document.addEventListener("wheel", blockScroll, { passive: false })
    return () => {
      document.removeEventListener("wheel", blockScroll)
    }
  }, [])

  return (
    <Box
      sx={{
        backgroundColor: "#f4f6f8",
        zIndex: 213,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}>
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) scale(1.2)"
        }}>
        {logo}
      </Box>
    </Box>
  )
}

export default LogoLoading
