const colors = {
  background: {
    default: "#f4f6f8"
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a"
  },

  transparent: {
    main: "transparent"
  },

  white: {
    main: "#fefefe",
    focus: "#fefefe"
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000"
  },

  primary: {
    main: "#1A73E8",
    focus: "#1A73E8"
  },

  secondary: {
    main: "#1eaee8",
    focus: "#1eaee8"
  },
  third: {
    main: "#23d9e7",
    focus: "#23d9e7"
  },
  info: {
    main: "#1A73E8",
    focus: "#1662C4"
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a"
  },

  warning: {
    main: "#6706de",
    focus: "#ac69ff"
  },

  network: {
    main: "#7c00ff",
    focus: "#3e0b9a"
  },
  error: {
    main: "#e42992",
    focus: "#f65f53"
  },

  light: {
    main: "#f4f6f8",
    focus: "#f4f6f8"
  },

  dark: {
    main: "#344767",
    focus: "#2c3c58"
  },

  grey: {
    100: "#f8f9fa",
    200: "#f4f6f8",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529"
  },

  network: {
    main: "#7c00ff",
    state: "#3e0b9a"
  },

  gradients: {
    primary: {
      main: "#EC407A",
      state: "#D81B60"
    },

    secondary: {
      main: "#747b8a",
      state: "#495361"
    },
    feed: {
      main: "#747b8a",
      state: "#495361"
    },

    info: {
      main: "#49a3f1",
      state: "#1A73E8"
    },

    success: {
      main: "#66BB6A",
      state: "#43A047"
    },

    warning: {
      main: "#7c00ff",
      state: "#3e0b9a"
    },

    network: {
      main: "#7c00ff",
      state: "#3e0b9a"
    },
    error: {
      main: "#e062b5",
      state: "#e42992"
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA"
    },

    dark: {
      main: "#42424a",
      state: "#191919"
    }
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86"
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec"
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d"
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c"
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22"
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a"
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2"
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874"
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177"
    },

    github: {
      main: "#24292e",
      dark: "#171a1d"
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00"
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749"
    }
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b"
    },

    secondary: {
      background: "#1eaee8",
      text: "#6c757d"
    },

    info: {
      background: "#aecef7",
      text: "#095bc6"
    },

    success: {
      background: "#bce2be",
      text: "#339537"
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000"
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200"
    },

    light: {
      background: "#fefefe",
      text: "#c7d3de"
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a"
    },
    network: {
      background: "#7c00ff",
      text: "#3e0b9a"
    }
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",

    network: "#7c00ff",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040"
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" }
  }
}

export default colors
