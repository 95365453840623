import PropTypes from "prop-types"
import MDBox from "../../components/MDBox"
import MDTypography from "../../components/MDTypography"
import typography from "../../asset/theme/base/typography"
import { Grid } from "@mui/material"
import { TermsAndConditions } from "../../pages/register/TermsAndConditions"
import TermsAndConditionsDialog from "../../pages/register/TermsAndConditionDialog"
import { useState } from "react"
function Footer({ company }) {
  const { href, name } = company
  const { size } = typography
  const [showTerms, setShowTerms] = useState(false)
  const handleOpenTerms = () => {
    setShowTerms(true)
  }
  const handleCloseTerms = () => {
    setShowTerms(false)
  }
  return (
    <Grid item xs={12} md={6} xl={3} sx={{ opacity: 0.66, marginBottom: "calc(env(safe-area-inset-bottom) - 1rem)" }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color="text" fontSize={size.sm} px={1.5}>
        Copyright &copy; {new Date().getFullYear()} &nbsp;
        <MDTypography variant="button" fontWeight="medium">
          MyDigitalEstate
        </MDTypography>
      </MDBox>
      <MDBox sx={{ fontSize: "12px", margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <TermsAndConditions fontSize="12px" onClick={handleOpenTerms} />
      </MDBox>
      <TermsAndConditionsDialog open={showTerms} onCloseDialog={handleCloseTerms} showAcceptButton={false} />
      <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color="text" fontSize={10} px={1.5}>
        Developed by Futuri Enterprise
      </MDBox>
      <br />
      <br />
    </Grid>
  )
}

Footer.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "MyDigitalEstate" }
}
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object)
}

export default Footer
